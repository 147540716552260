import BasePlugin from '../BasePlugin'

export default class MatchAdjustmentRequest extends BasePlugin {
  async execute () {
    let adjustmentRequestData = {
      'adjustmentRequestId': this.context.getParentContext().getModel().id,
      'actionTypeId': 2
    }

    this.context.$msgbox.close()

    let datesResponse = await this.context.$http.get(
      `${this.context.$config.api}/registryservice/plugins/execute/CheckAdjustmentRequestAndInvestmentRequestDatesQuery?adjustmentRequestId=${this.context.getParentContext().getModel().id}`
    )

    if (!datesResponse.data.is_equal_dates) {
      let message = `<p> Проектные сроки отличаются от текущих (сроки инвестзаявки: ${datesResponse.data.investment_request_start_date} - ${datesResponse.data.investment_request_end_date} , новые сроки: ${datesResponse.data.scenario_start_date} - ${datesResponse.data.scenario_end_date} ).Вы действительно хотите согласовать новые сроки?<br>`
      this.context.$msgbox({
        type: 'info',
        confirmButtonText: 'Да',
        showCancelButton: true,
        center: true,
        message: message,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.setAdjustmentRequestRule(adjustmentRequestData)
            done()
          } else {
            done()
            this.context.$msgbox.close()
          }
        }
      })
    } else {
      this.setAdjustmentRequestRule(adjustmentRequestData)
    }
  }

  setAdjustmentRequestRule (adjustmentRequestData) {
    this.context.$msgbox.close()
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddApprovalHistoryRecordByAdjustmentRequestTypeCommand`,
      { 'adjustmentRequestId': this.context.getParentContext().getModel().id, 'action': 'match' },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetAdjustmentRequestRuleCommand`,
      adjustmentRequestData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    this.context.$message({
      type: 'success',
      message: 'Процедура согласована'
    })
    this.context.getParentContext().getCard().$emit('cancelChanges')
  }
}
